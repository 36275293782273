aside {
  z-index: 9;
  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  right: -40px;
}

/*# sourceMappingURL=notifications.c4200eee.css.map */
